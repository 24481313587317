// json太菜了，改为用js文件来存数值
// 左侧导航栏用
// 格式如下，没有的项最好不要出现在json里：
// [{
//   "icon": "",
//   "name": "",
//   "route": "",
//   "children": [{
//     "icon": "",
//     "name": "",
//     "route": "",
//   },{
//     "icon": "",
//     "name": "",
//     "route": "",
//   }]
// }]
export default [{
  "icon": "el-icon-s-home",
  "name": "首页",
  "route": "mainPage",
},{
  "icon": "el-icon-user-solid",
  "name": "用户管理",
  "route": "user",
},{
  "icon": "el-icon-s-order",
  "name": "交易管理",
  "route": "order",
},{
  "icon": "el-icon-watch",
  "name": "自动扣费管理",
  "route": "autotime",
},{
  "icon": "el-icon-date",
  "name": "牌桌设置管理",
  "route": "tableoption",
},{
  "icon": "el-icon-coin",
  "name": "比赛查询",
  "route": "match",
},{
  "icon": "el-icon-tickets",
  "name": "单局查询",
  "route": "game",
},{
  "icon": "el-icon-tickets",
  "name": "排名查询",
  "route": "rank",
},{
  "icon": "el-icon-tickets",
  "name": "图片上传",
  "route": "upload",
},{
  "icon": "el-icon-tickets",
  "name": "公告修改",
  "route": "message",
},{
  "icon": "el-icon-tickets",
  "name": "图片管理",
  "route": "picture",
},{
  "icon": "el-icon-tickets",
  "name": "备份状态",
  "route": "backup",
}]
