import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const home = r => require.ensure([], () => r(require('@/pages/home')), 'home');
const login = r => require.ensure([], () => r(require('@/pages/login/Login')), 'login');
const mainpage = r => require.ensure([], () => r(require('@/components/mainPage')), 'mainpage');

const game = r => require.ensure([], () => r(require('@/pages/main/game')), 'game');
const match = r => require.ensure([], () => r(require('@/pages/main/match')), 'match');
const order = r => require.ensure([], () => r(require('@/pages/main/order')), 'order');
const autotime = r => require.ensure([], () => r(require('@/pages/main/autotime')), 'autotime');
const tableoption = r => require.ensure([], () => r(require('@/pages/main/tableoption')), 'tableoption');
const ready = r => require.ensure([], () => r(require('@/pages/main/ready')), 'ready');
const table = r => require.ensure([], () => r(require('@/pages/main/table')), 'table');
const user = r => require.ensure([], () => r(require('@/pages/main/user')), 'user');
const rank = r => require.ensure([], () => r(require('@/pages/main/rank')), 'rank');

const upload = r => require.ensure([], () => r(require('@/pages/main/upload')), 'upload');
const message = r => require.ensure([], () => r(require('@/pages/main/message')), 'message');

const picture = r => require.ensure([], () => r(require('@/pages/main/picture')), 'picture');
const backup = r => require.ensure([], () => r(require('@/pages/main/backup')), 'backup');

const routes = [
	{
		path: '/',
		component: login
	},
	{
		path: '/login',
		component: login
	},
	{
		path: '/mainpage',
		component: mainpage,
		name: '',
		children: [{
			path: '',
			component: home,
			meta: [],
		},{
			path: '/home',
			component: home,
			meta: [],
		},{
			path: '/game',
			component: game,
			meta: ['单局管理'],
		},{
			path: '/match',
			component: match,
			meta: ['比赛管理'],
		},{
			path: '/order',
			component: order,
			meta: ['交易管理'],
		},{
			path: '/autotime',
			component: autotime,
			meta: ['自动扣费管理'],
		},{
			path: '/tableoption',
			component: tableoption,
			meta: ['牌桌设置管理'],
		},{
			path: '/ready',
			component: ready,
			meta: ['约桌管理'],
		},{
			path: '/table',
			component: table,
			meta: ['牌桌管理'],
		},{
			path: '/user',
			component: user,
			meta: ['用户管理'],
		},{
			path: '/rank',
			component: rank,
			meta: ['排行管理'],
		},{
			path: '/upload',
			component: upload,
			meta: ['图片上传'],
		},{
			path: '/message',
			component: message,
			meta: ['公告修改'],
		},{
			path: '/picture',
			component: picture,
			meta: ['图片管理'],
		},{
			path: '/backup',
			component: backup,
			meta: ['图片管理'],
		}
	]},

]

export default new Router({
	routes,
	strict: process.env.NODE_ENV !== 'production',
})
